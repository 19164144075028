.bookContainer{
    width: 100%;
    padding: 2rem;
    display: flex;
    height: fit-content;
    justify-content: center;
    background-color: #f3f3f3;
    align-items: center;
    min-height: calc(100vh - 73px)
}

.bookWrapper{
    width: 60%;
    padding: 2rem;
    border-radius: 12px;
    background-color: #fff;
    margin: 3rem;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
}

.bookDate label{
    font-weight: bold;
    font-size: 18px;
    color: rgb(64,62,61);
}

.bookDate input,.bookTime input{
    padding: 0.8rem;
}
.bookDate{
    display: flex;
    flex-direction: column;
    gap:0.4rem
}
form {
    width: 100%;
    margin-top:2rem;
}
.bookMessage{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.bookMessage p{
    font-weight: 700;
    text-align: center;
}

.bookFormData{
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    width: 100%;
}

.bookLeft,.bookRight{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.bookNow button{
    /* background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), rgb(251, 195, 97); */
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    display: inline-block;
    font-family: FuturaPT, sans-serif;
    font-size: 18px;
    font-weight: 700;
    height: 48px;
    min-width: 160px;
    padding: 0px 15px;
    line-height: 100%;
}

.bookNow button:hover{
    background-color: rgb(252, 208, 131);
}

.bookNow{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
}
input{
    width: 100%;
    border: 1px solid black;
    outline: none;
    padding: 1.4rem 1rem 0.5rem 1rem;
    font-size: 18px;
    font-weight: bold;
    border-radius: 13px;
    background: transparent;
    color: rgb(64,62,61);
}
.bookLeft div label{
    position: absolute;
    left:1rem;
    pointer-events: none;
    top:50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    color: rgb(64,62,61);
    transition: all 250ms ease-in-out;

}

.bookLeft div{
    position: relative;
}


input:focus ~ label,
input:valid ~ label{
    transform: translateY(-23px);
    padding-bottom: 0.5rem;
    font-size: 13px;
}


.bookRight select{
    width: 100%;
    outline: none;
    padding-left: 0.7rem;
    height: 57px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 13px;
    color: rgb(64,62,61);
}


select option{
    font-weight: bold;
    font-size: 15px;
    color: rgb(64,62,61);
    padding: 0.5rem 1rem;
}

.disableBtn{
    cursor: not-allowed !important;
}

.popupContainer{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupWrapper{
    background-color: #fff;
    padding:3rem;
    cursor:default;
    box-shadow: rgb(59 59 59 / 9%) 0px 5px 15px 0px;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    gap:1rem
}

.popupBtns{
    display: flex;
    justify-content: space-between;
}

.popupBtns a{
    background: #E9703E;
    background-blend-mode: soft-light, normal;
    color: rgb(64, 62, 61);
    cursor: pointer;
    outline: none;
    text-decoration: none;
    box-shadow: initial;
    transition: all 100ms linear 0s;
    border-radius: 100px;
    border-width: 0px;
    font-family: FuturaPT, sans-serif;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 33px;
    min-width: 70px;
    padding: 0px 15px;
    line-height: 100%;
    margin-top: 2rem;
}
.popupBtns a:hover{
    background-color: rgb(252, 208, 131);
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media screen  and (max-width:850px){
    .bookWrapper{
        width: 100%;
    }
}
@media screen  and (max-width:750px){
    .bookWrapper{
        margin: 3rem 0;
    }
}
@media screen  and (max-width:650px){
    .bookFormData{
        flex-direction: column;
        gap:1.5rem;
    }

    .bookLeft{
        gap:1.5rem
    }
    
}
@media screen  and (max-width:510px){
    .bookWrapper{
        padding: 2rem 1rem;
    }
    .bookContainer{
        padding: 2rem 1rem;
    }

    .bookMessage h1{
        width: 100%;
        text-align: center;
    }
    .bookNow{
        margin-top: 0;
    }
}